// app/javascript/controllers/dropdown_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];

  connect() {    
    
    //sections in page
    const consilience=document.getElementById('front2');
    const articles=document.getElementById('articles');
    const people=document.getElementById('people');
    const top = document.getElementById('bc');
    const partners = document.getElementById('partners');
    const contact = document.getElementById('contact');

    //links in navbar
    const homeLink = document.getElementById('homeLink')
    const productsLink = document.getElementById('productsLink')
    const peopleLink = document.getElementById('peopleLink')
    const partnersLink = document.getElementById('partnersLink')
    const contactLink = document.getElementById('contactLink')
    this.linksArray=[homeLink,productsLink,peopleLink,partnersLink,contactLink]

    //links in dropdown
    const dropdownMenu=document.getElementById('dropdown');
    const dropdownLinks=dropdownMenu.querySelectorAll('a');
    const dropdownLinksArray=Array.from(dropdownLinks);
    //hide drop down when a link inside it is clicked
    dropdownLinksArray.forEach(link=>{
        link.addEventListener('click',()=>dropdownMenu.classList.remove('show'));
        
    })
    
    // Function to show the dropdown
    const showDropdown = () => {
        dropdownMenu.classList.add('show');
    };

    // Function to hide the dropdown
    const hideDropdown = () => {
        dropdownMenu.classList.remove('show');
    };

    // Show dropdown on mouse enter over the dropdown link
    productsLink.addEventListener('mouseenter', showDropdown);

    // Hide dropdown on mouse leave only if leaving both link and menu
    productsLink.addEventListener('mouseleave', (event) => {
        // Check if the mouse is moving to the dropdown menu
        if (!dropdownMenu.contains(event.relatedTarget)) {
            hideDropdown();
        }
    });

    dropdownMenu.addEventListener('mouseleave', (event) => {
    // Check if the mouse is moving back to the products link
     if (!productsLink.contains(event.relatedTarget)) {
        hideDropdown();
        }
    });

    // const innerNav=document.getElementById('innerNav');

    const elements=[consilience,top,people,partners,contact,articles]
    // creating an observer for each section...
    this.createObserver(top,0.7,homeLink);
    this.createObserver(consilience,0.4,productsLink);
    this.createObserver(articles,0.4,productsLink);
    this.createObserver(people,0.20,peopleLink);
    this.createObserver(partners,1, partnersLink);
    this.createObserver(contact,0.6, contactLink);

    // Select all anchor elements within the off-canvas and make them hide the canvas upon selection
    const offCanvasLinks = document.querySelectorAll('#offcanvasExample a');
    offCanvasLinks.forEach(link => link.onclick = () => {
    const offcanvasElement = document.getElementById('offcanvasExample');
    const offcanvasInstance = bootstrap.Offcanvas.getInstance(offcanvasElement);
    if (offcanvasInstance) {
    offcanvasInstance.hide();
    }
});
  }

    createObserver(element, threshold, linkElement) {
    // Create the IntersectionObserver with the provided threshold
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                this.setActiveState(linkElement)
            }
        });
    }, {
        threshold: [threshold]
    });

    // Observe the provided element
    observer.observe(element);
}

    setActiveState(linkElement){
        this.linksArray.forEach(element=>{
            element == linkElement? element.classList.add('active'):element.classList.remove('active')
        })
    }

}
